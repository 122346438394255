import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../styles/variables";
import ImgixImg from "./ImgixImg";

interface HeaderImageProps {
  image: string;
}

const HeaderElem = styled.div`
  width: 100vw;
  height: 40vw;
  min-height: 25px;
  max-height: 600px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen AND (min-width: ${breakpoints.desktop}) {
    height: 450px;
  }

  @media screen AND (min-width: ${breakpoints.max}) {
    height: 600px;
  }
`;

const HeaderImage:React.FC<HeaderImageProps> = ({ image }) => (
  <HeaderElem>
    <ImgixImg src={image} imgixParams={imgix.fullImage} />
  </HeaderElem>
);

export default HeaderImage;

import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const GridElem = styled.section`
  padding: 30px 20px 40px;
  max-width: 1280px;
  margin: 0 auto;

  img {
    margin-bottom: 20px;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 30px 20px 45px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;

    img {
      margin-bottom: 30px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 40px 30px 50px;
    grid-gap: 60px;

    img {
      margin-bottom: 45px;
    }
  }

  @media screen AND (min-width: ${breakpoints.max}px) {
    padding: 45px 0px 100px;
    grid-gap: 90px;

    img {
      margin-bottom: 60px;
    }
  }
`;

const HalfGrid:React.FC = ({ children }) => <GridElem>{children}</GridElem>;

export default HalfGrid;

import React from "react";
import styled from "styled-components";

interface TextProps {
    text: string;
    grosserTitel?: string;
}

const TextElem = styled.article`
@media screen AND (min-width:1280px) {
    h2 {
        display: none;
    }
}
`;

const Text:React.FC<TextProps> = ({ text, grosserTitel }) => <TextElem>
    {grosserTitel ? <h2 className="h1">{grosserTitel}</h2> : ""}
    <div dangerouslySetInnerHTML={{__html: text}}></div>
</TextElem>;

export default Text;

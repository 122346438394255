import React from "react";
import { graphql } from "gatsby";
import HalfGrid from "../components/HalfGrid";
import HeaderImage from "../components/HeaderImage";
import Text from "../components/Text";
import DefaultLayout from "../layouts/default";
import ImgixImg from "../components/ImgixImg";
import { breakpoints, imgix } from "../styles/variables";
import styled from "styled-components";

const SpacedArticle = styled.article`
  padding-bottom: 40px;
`;

const Button = styled.a`
  background-color: #4aa851;
  color: #fff;
  border: 1px solid #4aa851;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const FullGrid = styled.section`
  padding: 30px 20px 40px;
  max-width: 1280px;
  margin: 0 auto;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 30px 20px 45px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 40px 30px 50px;
  }

  @media screen AND (min-width: ${breakpoints.max}px) {
    padding: 45px 0px 100px;
  }
`;
interface StandardProps {
  data: {
    cms: {
      entry: {
        title: string;
        grosserTitel: string;
        headerBild: [
          {
            url: string;
          }
        ];
        content_rows: [
          {
            type: string;
            bild: {
              url: string;
            };
            text: string;
            buttontext: string;
            buttonlink: string;
          }
        ];
      };
    };
  };
}

export const StandardQuery = graphql`
  query ($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        ... on CMS_main_default_Entry {
          grosserTitel
          headerBild {
            url
          }
          content_rows {
            type: __typename
            ... on CMS_content_rows_inhalt_BlockType {
              bild {
                url
              }
              text
              buttontext
              buttonlink
            }
            ... on CMS_content_rows_singlecontent_BlockType {
              text
              buttontext
              buttonlink
            }
          }
        }
      }
    }
  }
`;

const Standard: React.FC<StandardProps> = ({ data }) => {
  return (
    <DefaultLayout
      grosserTitel={data.cms.entry.grosserTitel}
      title={data.cms.entry.title}
    >
      {data.cms.entry.headerBild.length > 0 ? (
        <HeaderImage image={data.cms.entry.headerBild[0].url} />
      ) : (
        ""
      )}
      {data.cms.entry.content_rows.length > 0
        ? data.cms.entry.content_rows.map((row, index) => {
            if (row.type === "CMS_content_rows_inhalt_BlockType") {
              return (
                <HalfGrid key={index}>
                  <SpacedArticle>
                    <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
                    {row.buttonlink && row.buttontext && (
                      <Button href={row.buttonlink}>{row.buttontext}</Button>
                    )}
                  </SpacedArticle>

                  <div>
                    {row.bild.length > 0 && (
                      <ImgixImg
                        src={row.bild[0].url}
                        imgixParams={imgix.halfImage}
                      />
                    )}
                  </div>
                </HalfGrid>
              );
            } else if (
              row.type === "CMS_content_rows_singlecontent_BlockType"
            ) {
              return (
                <FullGrid key={index}>
                  <Text text={row.text} />
                  {row.buttonlink && row.buttontext && (
                    <Button href={row.buttonlink}>{row.buttontext}</Button>
                  )}
                </FullGrid>
              );
            }
          })
        : ""}
    </DefaultLayout>
  );
};

export default Standard;
